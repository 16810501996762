'use client';

import React, { AriaRole } from 'react';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';
import clsx from 'clsx';

import styles from './styles.module.css';

import { ExtendedLink } from '@/components/ExtendedLink';
import { ListProduct } from '@/modules/product/types';
import { buildProductImageProps } from '@/modules/pictures/helpers';

export type Props = Pick<ListProduct, 'sellerUserName' | 'preview'> & {
  productHref: string;
  ProductAttributes?: React.FC;
  BoostedTag?: React.FC;
  HoverOverlay?: React.FC;
  Overlay?: React.FC;
  handleClick?: () => void;
  Wrapper?: React.FC<{ children: React.ReactNode }>;
  disableLazyLoading?: boolean;
  role?: AriaRole;
};

export function ProductCard({
  productHref,
  preview,
  sellerUserName,
  ProductAttributes,
  HoverOverlay,
  Overlay,
  handleClick,
  Wrapper,
  disableLazyLoading = false,
  role,
}: Props) {
  const MaybeLink = Wrapper ? Wrapper : ExtendedLink;
  const imageProps = buildProductImageProps({ preview, sellerUserName });
  const productImageContainerStyles = clsx(
    HoverOverlay
      ? styles.productImageContainer__withHoverOverlay
      : styles.productImageContainer__withoutHoverOverlay
  );

  return (
    <div className={styles.productCardRoot} role={role}>
      <div className={productImageContainerStyles}>
        <MaybeLink
          href={`/products/${productHref}`}
          onClick={handleClick}
          prefetch={false}
          aria-label={imageProps?.alt}
        >
          <LazyLoadImage
            disableLazyLoading={disableLazyLoading}
            {...imageProps}
          />
          {Overlay && (
            <div className={styles.overlay}>
              <Overlay />
            </div>
          )}
          {HoverOverlay && (
            <div className={styles.hoverOverlay}>
              <HoverOverlay />
            </div>
          )}
        </MaybeLink>
      </div>
      {ProductAttributes && <ProductAttributes />}
    </div>
  );
}
