import { ListProduct } from '@/modules/product/types';

export function getSizeString({
  sizes,
  multipleSizesString,
}: {
  sizes: ListProduct['sizes'];
  multipleSizesString: string;
}) {
  if (!sizes) {
    return;
  }
  if (sizes.length > 1) {
    return multipleSizesString;
  }

  /**
   * Some products, e.g. accessories, have no sizes and the API expresses this as product.sizes: [].
   * In these instances, we should return an empty string so that we can differentiate between
   * these products and products that have sizes deliberately ommitted.
   */
  return sizes[0] ?? '';
}
