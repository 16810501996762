import React from 'react';

import styles from './styles.module.css';
import { CollapsibleRow } from './CollapsibleRow';

import {
  SizeFilterBranch,
  childrenAreLeafNodes,
} from '@/modules/filters/types';

export function SizeFilterRow({
  id,
  name,
  children,
  depth = 0,
  Leaf,
}: SizeFilterBranch & {
  depth?: number;
  Leaf: (props: { name: string; value: string }) => React.ReactNode;
}) {
  if (!children) {
    return null;
  }

  /**
   * If the children are leaf nodes (i.e. sizes) then we are
   * at full depth and render them inside a grid.
   */
  if (childrenAreLeafNodes(children)) {
    return (
      <CollapsibleRow key={`${id}-${name}`} label={name} depth={depth}>
        <div
          key={`${id}-${name}`}
          role="listbox"
          className={styles.sizeButtonsContainer}
          style={{ '--depth': depth } as React.CSSProperties}
        >
          {children.map(({ name, composite_id }) => (
            <Leaf
              key={`${name}-${composite_id}`}
              name={name}
              value={composite_id}
            />
          ))}
        </div>
      </CollapsibleRow>
    );
  }

  /**
   * Otherwise recursively render children within collapsible dropdown rows.
   */
  return (
    <CollapsibleRow label={name} depth={depth}>
      {children.map(({ id, name, children }) => (
        <SizeFilterRow
          key={`${id}-${name}`}
          id={id}
          name={name}
          depth={depth + 1}
          Leaf={Leaf}
          // eslint-disable-next-line react/no-children-prop
          children={children}
        />
      ))}
    </CollapsibleRow>
  );
}
