'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import { LikeIcon } from '@depop/web-ui-kit/Icons/LikeIcon';
import { LikeBoldIcon } from '@depop/web-ui-kit/Icons/LikeBoldIcon';
import { Modal } from '@depop/web-ui-kit/Modal';

import styles from './styles.module.css';

import { ButtonMinimal } from '@/components/ButtonMinimal';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { addProductLike, deleteProductLike } from '@/modules/likes/api';
import { sendBoostedAttributionClick } from '@/modules/impressions/api';
import { useProductTracking } from '@/modules/product/hooks/useProductTracking';
import { DownloadAppModal } from '@/components/DownloadAppModal/DownloadAppModal';

type Props = {
  productId: number;
  initialLikedState: boolean;
  isProductBoosted?: boolean;
  iconSize?: number;
  className?: string;
};

export function LikeButton({
  productId,
  initialLikedState,
  isProductBoosted,
  iconSize = 28,
  className,
}: Props) {
  const [isAuthenticated] = useCurrentUser();
  const [isLiked, setIsLiked] = useState(initialLikedState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sendProductInteractionActionATEvent } = useProductTracking();
  const IconComponent = isLiked ? LikeBoldIcon : LikeIcon;

  function sendProductInteractionAction(interactionType: string) {
    sendProductInteractionActionATEvent(productId, interactionType, {
      isBoosted: isProductBoosted,
    });
  }

  async function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    /**
     * @TECHDEBT PB 23/2/23
     * Allowing propagation to match the old site, whereby a Like click
     * also sends a SEARCH_RESULTS_PRODUCT_ACTION event on the underlying
     * Product Card.
     */
    // e.stopPropagation();

    if (!isAuthenticated) {
      setIsModalOpen(true);
      sendProductInteractionAction('ProductLike');
      return;
    }

    const newLikeState = !isLiked;

    setIsLiked(newLikeState);

    if (newLikeState) {
      try {
        await addProductLike(productId);
        sendProductInteractionAction('ProductLike');
        if (isProductBoosted) {
          sendBoostedAttributionClick(productId);
        }
      } catch {
        /**
         * Set button back to previous state if request fails
         */
        setIsLiked(false);
      }
    } else {
      try {
        await deleteProductLike(productId);
        sendProductInteractionAction('ProductUnlike');
      } catch {
        /**
         * Set button back to previous state if request fails
         */
        setIsLiked(true);
      }
    }
  }

  return (
    <div className={className}>
      <ButtonMinimal
        onClick={handleClick}
        className={styles.likeButton}
        title={(isLiked ? 'Unlike' : 'Like') + ' Product'}
        {...(!isAuthenticated && {
          'aria-haspopup': 'dialog',
          'aria-controls': 'get-app-modal',
        })}
      >
        <IconComponent
          size={iconSize}
          className={clsx({
            [styles.likedButtonFilled]: isLiked,
            [styles.likeButtonUnfilled]: !isLiked,
          })}
        />
      </ButtonMinimal>
      {!isAuthenticated && (
        <Modal
          id="get-app-modal"
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          content={() => <DownloadAppModal />}
          label="Get the App - Download Modal"
          overlayAriaLabel="Get the App - Download Modal"
        />
      )}
    </div>
  );
}
