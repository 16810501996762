import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types.ts';
import {
  LIKED_PRODUCTS_V2_ENDPOINT,
  UPDATE_PRODUCT_LIKE_ENDPOINT,
} from '@/constants/endpoints.ts';
import {
  FetchLikedProductsRequest,
  LikedProductsResponse,
} from '@/modules/likes/types.ts';

export function addProductLike(productId: number) {
  return http.put(
    UPDATE_PRODUCT_LIKE_ENDPOINT.replace(':productId', productId.toString()),
    {},
    { withAuth: true }
  );
}

export function deleteProductLike(productId: number) {
  return http.delete(
    UPDATE_PRODUCT_LIKE_ENDPOINT.replace(':productId', productId.toString()),
    { withAuth: true }
  );
}

export function fetchLikedProducts(
  req: FetchLikedProductsRequest,
  userId: number
): Promise<AxiosCompatibleResponse<LikedProductsResponse>> {
  return http.get(
    LIKED_PRODUCTS_V2_ENDPOINT.replace(':userId', userId.toString()),
    {
      params: req?.params,
      withAuth: true,
    }
  );
}
