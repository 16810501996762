import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

import { getBackgroundColorStyleForColour } from '@/modules/colours/helpers';
import { ColourAttribute } from '@/modules/search/types';

export function ColourCircle({ colour }: { colour: ColourAttribute }) {
  return (
    <div className={styles.colourFilterOptionLabelContainer}>
      <div
        className={clsx(
          styles.colourFilterColourSpot,
          colour.id === 'white' && styles.greyBorder
        )}
        style={
          {
            '--background-image': getBackgroundColorStyleForColour(colour.id),
            '--background-color': '#' + colour.hexCode,
            '--border-color': colour.hexCode,
          } as React.CSSProperties
        }
      />
      {colour.nameI18N}
    </div>
  );
}
