import { isNotNull } from '../cms/helpers/isNotNull';

import { normaliseCompositeSizeId } from './helpers';
import {
  SizeFilters,
  SizeFilterBranch,
  SizeFilterLeaf,
  childrenAreLeafNodes,
} from './types';

import { FetchFilterAggregatesResponse } from '@/modules/search/types';

/**
 * Recursive algorithm that prunes empty leaf nodes from the size filter tree,
 * and then prunes empty branch nodes that have no leaves left after pruning
 * etc. etc.
 *
 * Diagram here: https://github.com/depop/web-default-website-server/pull/291
 *
 * @param node a branch node of the size filter data tree
 * @param set a set of size ids that are available in the aggregates
 * @returns a pruned branch node, or null if the branch itself should be pruned
 */
function recursivePrune(
  node: SizeFilterBranch,
  set: Set<string>
): SizeFilterBranch | null {
  if (node.children.length > 0) {
    let prunedChildren: SizeFilterLeaf[] | SizeFilterBranch[];

    if (childrenAreLeafNodes(node.children)) {
      /**
       * If children are leaf nodes (i.e. sizes) then we are at full depth.
       * Remove all sizes that have 0 products.
       */
      prunedChildren = node.children.filter(
        (child) =>
          normaliseCompositeSizeId(child.composite_id) &&
          set.has(normaliseCompositeSizeId(child.composite_id))
      );
    } else {
      /**
       * Otherwise, this branch leads to more branches. We need to recursively
       * prune those first.
       */
      prunedChildren = node.children
        .map((child) => recursivePrune(child, set))
        .filter(isNotNull);
    }

    /** if any child nodes remain after pruning, keep this branch in the tree */
    if (prunedChildren.length > 0) {
      return {
        id: node.id,
        name: node.name,
        children: prunedChildren,
      };
    }
  }

  /**
   * If we reach this point, we're at a branch node with no children left
   * after pruning. Return null so that this node can be filtered out by
   * its parent.
   */
  return null;
}

export function pruneEmptySizes(
  sizeTree: SizeFilters,
  aggregates: FetchFilterAggregatesResponse['sizes']
): SizeFilters {
  const availableSizesSet = new Set<string>(Object.keys(aggregates));

  if (!availableSizesSet.size) {
    return sizeTree;
  }

  const pruned = sizeTree
    .map((node) => recursivePrune(node, availableSizesSet))
    .filter(isNotNull);

  return pruned;
}
