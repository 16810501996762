import { ColourAttribute } from '@/modules/search/types';

export function getBackgroundColorStyleForColour(value: string) {
  const colourMap = {
    multi:
      'conic-gradient(from 0.25turn, #e02020, #fa6400 0.17turn, #f7b500 0.33turn, #6dd400 0.5turn, #0091ff 0.67turn, #6236ff 0.83turn, #ae22e3 0.96turn, #b620e0)',
    gold: 'linear-gradient(253deg, #dccd8f, #bc9700)',
    silver: 'linear-gradient(254deg, #d5d5d5, #7c7c7c)',
  };

  return colourMap[value as keyof typeof colourMap] ?? 'unset';
}

export function getColoursDataForSelect({
  colours,
}: {
  colours: ColourAttribute[];
}) {
  return colours
    .filter((colour) => colour.status === 'active')
    .map((colour) => ({
      id: colour.id,
      label: colour.nameI18N,
      value: colour.id,
      hexCode: colour.hexCode,
    }));
}
