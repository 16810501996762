'use client';
import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '@depop/web-ui-kit/Checkbox';
import { useTranslations } from 'next-intl';

import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';
import { MobileFilterFooter } from '../MobileFilterFooter';
import { MobileFilterHeader } from '../MobileFilterHeader';

import { ColourCircle } from './ColourCircle';
import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useSearchFilterAggregates } from '@/modules/search/useSearchFilterAggregates';
import { FilterTypes } from '@/modules/filters/types';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useFilterProductAttributes } from '@/modules/attributes/useFilterProductAttributes';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
};

export function ColourFilter({ closeMobileFilterDrawer, sellerId }: Props) {
  const {
    searchFilters: { colours },
    setSearchFilters: { setColourFilter },
  } = useSearchFilters();
  const { data: aggregates } = useSearchFilterAggregates({
    sellerId,
  });
  const t = useTranslations('common');
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  const { data } = useFilterProductAttributes();

  const coloursData = data?.data.colour;
  const coloursAggregateData = aggregates?.data.colours;

  const title = t('Colour.Title');

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          title={title}
          isActive={Boolean(colours?.length)}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Colour }
        )
      }
    >
      {({ className, toggleDropdown }) => (
        <div
          className={clsx(
            className,
            baseFilterStyles.searchFilterDropdownContents,
            styles.colourFilterDropdownContents
          )}
        >
          <MobileFilterHeader
            title={title}
            toggleDropdown={toggleDropdown}
            closeMobileFilterDrawer={closeMobileFilterDrawer}
          />
          <div className={baseFilterStyles.content}>
            {coloursData?.map((colour) => {
              const isChecked = colours?.includes(colour.id);
              const checkboxId = `checkbox-${colour.id}`;
              const isDisabled = !(
                coloursAggregateData && coloursAggregateData[colour.id]
              );

              return (
                <label
                  className={clsx(
                    baseFilterStyles.checkboxFilterDropdownOption,
                    styles.colourFilterDropdownOptionContainer,
                    styles.colourFilterDropdownOptionLabel,
                    {
                      [baseFilterStyles.filterOptionDisabled]: isDisabled,
                    }
                  )}
                  style={
                    {
                      '--font-weight': isChecked ? 'bold' : 'normal',
                    } as React.CSSProperties
                  }
                  key={colour.id}
                  htmlFor={checkboxId}
                >
                  <ColourCircle colour={colour} />
                  <Checkbox
                    id={checkboxId}
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={() => setColourFilter(colour.id)}
                  />
                </label>
              );
            })}
          </div>
          <MobileFilterFooter
            handleClear={() => setColourFilter()}
            handleSubmit={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
        </div>
      )}
    </Dropdown>
  );
}
