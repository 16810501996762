import React from 'react';
import clsx from 'clsx';
import { Checkbox } from '@depop/web-ui-kit/Checkbox';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';

import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';
import { MobileFilterFooter } from '../MobileFilterFooter';
import { MobileFilterHeader } from '../MobileFilterHeader';

import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useSearchFilterAggregates } from '@/modules/search/useSearchFilterAggregates';
import { useFilterProductAttributes } from '@/modules/attributes/useFilterProductAttributes';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { FilterTypes } from '@/modules/filters/types';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
  disableUnavailableOptions?: boolean;
};

export function ConditionFilter({
  closeMobileFilterDrawer,
  sellerId,
  disableUnavailableOptions = false,
}: Props) {
  const {
    searchFilters: { conditions },
    setSearchFilters: { setConditionFilter },
  } = useSearchFilters();
  const t = useTranslations('common');
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const { data: aggregates } = useSearchFilterAggregates({
    sellerId,
  });

  const { data } = useFilterProductAttributes();

  const conditionData = data?.data.condition;
  const conditionAggregateData = aggregates?.data.conditions;

  const title = t('Condition.Title');

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          title={title}
          isActive={Boolean(conditions?.length)}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Condition }
        )
      }
    >
      {({ className, toggleDropdown }) => (
        <div
          className={clsx(
            className,
            baseFilterStyles.searchFilterDropdownContents,
            styles.conditionFilterDropdownContents
          )}
        >
          <MobileFilterHeader
            title={title}
            toggleDropdown={toggleDropdown}
            closeMobileFilterDrawer={closeMobileFilterDrawer}
          />
          <div className={baseFilterStyles.content}>
            {conditionData?.map((condition) => {
              const isChecked = conditions?.includes(condition.id);
              const checkboxId = `checkbox-${condition.id}`;
              const isDisabled =
                disableUnavailableOptions &&
                !(
                  conditionAggregateData && conditionAggregateData[condition.id]
                );

              return (
                <label
                  key={condition.id}
                  htmlFor={checkboxId}
                  className={clsx(
                    baseFilterStyles.checkboxFilterDropdownOption,
                    styles.conditionFilterDropdownOptionContainer,
                    {
                      [baseFilterStyles.filterOptionDisabled]: isDisabled,
                    }
                  )}
                  style={
                    {
                      '--font-weight': isChecked ? 'bold' : 'normal',
                    } as React.CSSProperties
                  }
                >
                  <div>
                    <Text>{condition.nameI18N}</Text>
                    <Text type="caption1">{condition.descriptionI18N}</Text>
                  </div>
                  <Checkbox
                    id={checkboxId}
                    checked={isChecked}
                    onChange={() => setConditionFilter(condition.id)}
                    disabled={isDisabled}
                  />
                </label>
              );
            })}
          </div>
          <MobileFilterFooter
            handleClear={() => setConditionFilter()}
            handleSubmit={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
        </div>
      )}
    </Dropdown>
  );
}
