import { BoostedImpressionsAttribution } from './types';

import {
  BOOSTED_CLICK_ATTR_ENDPOINT,
  BOOSTED_IMPRESSIONS_ATTRIBUTION_ENDPOINT,
} from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';

export function updateBoostedImpressionsAttributions(
  impressions: BoostedImpressionsAttribution[]
): Promise<AxiosCompatibleResponse<void>> {
  return http.post(
    BOOSTED_IMPRESSIONS_ATTRIBUTION_ENDPOINT,
    {
      impressions,
    },
    {
      withAuth: true,
    }
  );
}

export function sendBoostedAttributionClick(
  productId: number
): Promise<AxiosCompatibleResponse<void>> {
  return http.post(
    BOOSTED_CLICK_ATTR_ENDPOINT,
    {
      productId,
    },
    {
      withAuth: true,
    }
  );
}
