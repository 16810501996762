import { InfiniteData } from '@tanstack/react-query';

import { AxiosCompatibleResponse } from '@/modules/http/types';

export function getProductsFromPaginatedResponse<
  Z,
  T extends { products: Z[] },
  U extends InfiniteData<AxiosCompatibleResponse<T>>,
>(
  data?: U
): U extends InfiniteData<AxiosCompatibleResponse<{ products: infer R }>>
  ? R
  : never;
export function getProductsFromPaginatedResponse<
  Z,
  T extends { products: Z[] },
  U extends InfiniteData<AxiosCompatibleResponse<T>>,
  Y,
>(data?: U, productTransformer?: (product: Z) => Y): Y[];
export function getProductsFromPaginatedResponse<
  Z,
  T extends { products: Z[] },
  U extends InfiniteData<AxiosCompatibleResponse<T>>,
  Y,
>(data?: U, productTransformer?: (product: Z) => Y) {
  if (productTransformer) {
    return data?.pages.flatMap((page) =>
      page.data.products.map(productTransformer)
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data?.pages.flatMap((page) => page.data.products) as any;
}
