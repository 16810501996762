'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import { Button } from '@depop/web-ui-kit/Button';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';
import { MobileFilterHeader } from '../MobileFilterHeader';
import { MobileFilterFooter } from '../MobileFilterFooter';

import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { getCurrencySymbolFromCountry } from '@/modules/currencies/helpers';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { FilterTypes } from '@/modules/filters/types';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

type Props = { closeMobileFilterDrawer: () => void; sellerId?: number };

export function PriceFilter({ closeMobileFilterDrawer, sellerId }: Props) {
  const {
    searchFilters: { priceMin, priceMax },
    setSearchFilters: { setPriceFilter },
  } = useSearchFilters();
  const [newPriceValues, setNewPriceValues] = useState<{
    priceMin?: number;
    priceMax?: number;
  }>({
    priceMin,
    priceMax,
  });
  const [hasError, setHasError] = useState<boolean>(false);
  const commonT = useTranslations('common');
  const searchT = useTranslations('search');
  const { location } = useCurrentLocation();
  const currencySymbol = getCurrencySymbolFromCountry(location);
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  function handleMinPriceChange({ value }: { value: string }) {
    setHasError(false);
    setNewPriceValues({ ...newPriceValues, priceMin: Number(value) });
  }

  function handleMaxPriceChange({ value }: { value: string }) {
    setHasError(false);
    setNewPriceValues({ ...newPriceValues, priceMax: Number(value) });
  }

  function handleReset(toggleDropdown: () => void) {
    const resetPriceValues = { priceMin: undefined, priceMax: undefined };
    setHasError(false);
    setNewPriceValues(resetPriceValues);
    setPriceFilter(resetPriceValues);
    toggleDropdown();
  }

  function handleSubmit(toggleDropdown: () => void) {
    if (
      newPriceValues.priceMin &&
      newPriceValues.priceMax &&
      newPriceValues.priceMin > newPriceValues.priceMax
    ) {
      return setHasError(true);
    }

    setPriceFilter(newPriceValues);
    toggleDropdown();
  }

  const title = commonT('Price');

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          isActive={Boolean(priceMin) || Boolean(priceMax)}
          title={title}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Price }
        )
      }
    >
      {({ toggleDropdown, className }) => (
        <div
          className={clsx(
            className,
            baseFilterStyles.searchFilterDropdownContents,
            styles.priceFilterDropdownContents
          )}
        >
          <MobileFilterHeader
            title={title}
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            toggleDropdown={toggleDropdown}
          />
          <div className={baseFilterStyles.content}>
            <div className={styles.priceFilterInputRow}>
              <div className={styles.priceFilterInputContainer}>
                <label
                  className={styles.priceFilterInputLabel}
                  htmlFor="priceMin"
                >
                  {searchT('Min')}
                </label>
                <NumericFormat
                  className={clsx(styles.priceFilterInput, {
                    [styles.priceFilterInputError]: hasError,
                  })}
                  aria-label={searchT('MinimumPrice')}
                  prefix={currencySymbol}
                  placeholder={`${currencySymbol}1`}
                  value={newPriceValues.priceMin || null}
                  onValueChange={handleMinPriceChange}
                  allowNegative={false}
                  decimalScale={0}
                  name="priceMin"
                  id="priceMin"
                />
              </div>
              <hr className={styles.priceFilterHr} />
              <div className={styles.priceFilterInputContainer}>
                <label
                  className={styles.priceFilterInputLabel}
                  htmlFor="priceMax"
                >
                  {searchT('Max')}
                </label>
                <NumericFormat
                  className={styles.priceFilterInput}
                  aria-label={searchT('MaximumPrice')}
                  prefix={currencySymbol}
                  placeholder="any"
                  value={newPriceValues.priceMax || null}
                  onValueChange={handleMaxPriceChange}
                  allowNegative={false}
                  decimalScale={0}
                  name="priceMax"
                  id="priceMax"
                />
              </div>
            </div>
            {hasError && (
              <Text className={styles.priceFilterErrorMessage} type="caption1">
                {searchT('PriceFilter.ErrorMessage')}
              </Text>
            )}
            <div className={styles.priceFilterButtonRow}>
              <Button
                className={styles.priceFilterButton}
                outline={true}
                onClick={() => {
                  handleReset(toggleDropdown);
                }}
              >
                {searchT('Reset')}
              </Button>
              <Button
                className={styles.priceFilterButton}
                onClick={() => {
                  handleSubmit(toggleDropdown);
                }}
              >
                {searchT('Done')}
              </Button>
            </div>
          </div>
          <MobileFilterFooter
            handleSubmit={() => {
              handleSubmit(toggleDropdown);
              closeMobileFilterDrawer();
            }}
            handleClear={() =>
              setPriceFilter({
                priceMin: undefined,
                priceMax: undefined,
              })
            }
            sellerId={sellerId}
          />
        </div>
      )}
    </Dropdown>
  );
}
