'use client';

import React from 'react';
import { CheckboxWithLabel } from '@depop/web-ui-kit/CheckboxWithLabel';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import baseFilterStyles from '../baseFilterStyles.module.css';

import styles from './styles.module.css';

import { useSearchFilters } from '@/modules/search/useSearchFilters';

export function OnSaleFilter() {
  const {
    searchFilters: { isDiscounted },
    setSearchFilters: { setIsDiscountedFilter },
  } = useSearchFilters();
  const t = useTranslations('search');

  function handleChange() {
    setIsDiscountedFilter(!isDiscounted);
  }

  return (
    <CheckboxWithLabel
      className={clsx(
        baseFilterStyles.searchFilterTrigger,
        styles.onSaleFilterTrigger,
        {
          [baseFilterStyles.searchFilterTriggerActive]: isDiscounted,
        }
      )}
      checked={isDiscounted}
      onChange={handleChange}
      label={t('OnSale')}
    />
  );
}
