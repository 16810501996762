import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import baseFilterStyles from '../baseFilterStyles.module.css';
import { SearchFilterTrigger } from '../SearchFilterTrigger';
import { MobileFilterFooter } from '../MobileFilterFooter';
import { MobileFilterHeader } from '../MobileFilterHeader';

import { SizeFilterRow } from './SizeFilterRow';
import { SizeBox } from './SizeBox';
import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { pruneEmptySizes } from '@/modules/filters/pruneEmptySizes';
import { useSearchFilterAggregates } from '@/modules/search/useSearchFilterAggregates';
import { useSizeFilters } from '@/modules/filters/useSizeFilters';
import { FilterTypes, isBranchNode } from '@/modules/filters/types';
import { selectBranchFromCategory } from '@/modules/filters/selectBranchFromCategory';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
};

export function SizeFilter({ closeMobileFilterDrawer, sellerId }: Props) {
  const t = useTranslations('common');
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  const title = t('Size');

  const { data: sizeFilterTree, isLoading } = useSizeFilters();

  const { searchFilters, setSearchFilters } = useSearchFilters();

  const { data: aggregates } = useSearchFilterAggregates({
    sellerId,
  });
  const isSizeFilterDisabled = isLoading || !sizeFilterTree?.data?.length;

  function handleSizeBoxClick(value: string) {
    setSearchFilters.setSizeFilter(value);
  }

  const prunedSizeFilterTree = isSizeFilterDisabled
    ? []
    : pruneEmptySizes(sizeFilterTree.data, aggregates?.data.sizes ?? {});

  const sizeTree = selectBranchFromCategory(
    prunedSizeFilterTree,
    searchFilters.category,
    searchFilters.subcategories
  );

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          isActive={Boolean(searchFilters.sizes)}
          title={title}
          disabled={isSizeFilterDisabled}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          {
            filterType: FilterTypes.Size,
          }
        )
      }
    >
      {({ className, toggleDropdown }) => (
        <div
          className={clsx(
            className,
            {
              [baseFilterStyles.searchFilterDropdownContents]:
                !isSizeFilterDisabled,
            },
            styles.sizeFilterContents
          )}
        >
          <MobileFilterHeader
            title={title}
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            toggleDropdown={toggleDropdown}
          />
          {
            <div className={baseFilterStyles.content}>
              {sizeTree.map((node) => {
                if (!isBranchNode(node)) {
                  throw Error(
                    'Something has gone wrong in size filter narrowing logic'
                  );
                }

                const { id, name, children } = node;
                return (
                  <SizeFilterRow
                    key={`${name}-${id}`}
                    id={id}
                    name={name}
                    // eslint-disable-next-line react/no-children-prop
                    children={children}
                    Leaf={({
                      name,
                      value,
                    }: {
                      name: string;
                      value: string;
                    }) => (
                      <SizeBox
                        name={name}
                        value={value}
                        isSelected={Boolean(
                          searchFilters.sizes?.includes(value)
                        )}
                        handleClick={handleSizeBoxClick}
                      />
                    )}
                  />
                );
              })}
            </div>
          }
          <MobileFilterFooter
            handleSubmit={() => {
              toggleDropdown();
              closeMobileFilterDrawer();
            }}
            handleClear={() => setSearchFilters.setSizeFilter()}
            sellerId={sellerId}
          />
        </div>
      )}
    </Dropdown>
  );
}
