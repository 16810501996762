import { useLayoutEffect, useState } from 'react';

export function useSearchDrawer() {
  const [isOpen, setIsOpen] = useState(false);

  function handleEscKey(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  }

  /**
   * This layout effect removes the overflow of the 'background'
   * whilst the mobile filter popover is open. Without it a scroll
   * bar would render from the overflowing background content.
   */
  useLayoutEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    document.body.addEventListener('keydown', handleEscKey);
    return () => {
      document.body.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  return { isOpen, setIsOpen };
}
