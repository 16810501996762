import React from 'react';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

/**
 * @TECHDEBT PB 12/3/24 - This should be a checkbox component
 *
 * "It is highly recommended using the HTML select element,
 *  or a group of radio buttons if only one item can be selected,
 *  or a group of checkboxes if multiple items can be selected,
 *  because there is a lot of keyboard interactivity to manage
 *  focus for all the descendants, and native HTML elements provide
 *  this functionality for you for free.""
 *  > https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role
 */
export function SizeBox({
  name,
  value,
  isSelected,
  handleClick,
}: {
  name: string;
  value: string;
  isSelected: boolean;
  handleClick: (value: string) => void;
}) {
  return (
    <button
      role="option"
      aria-selected={isSelected}
      className={clsx(
        styles.sizeButton,
        isSelected && styles.sizeButtonSelected
      )}
      onClick={() => handleClick(value)}
    >
      <Text className={styles.sizeButtonText}>{name}</Text>
    </button>
  );
}
