import { SearchFilters } from '../search/useSearchFilters';

import { TOP_LEVEL_CATEGORY_IDS } from './constants';
import { SizeFilters, isBranchNode } from './types';

/**
 * Selects the branch or branches of the size filter tree that map to the currently
 * applied category and subcategory filters.
 *
 * @param sizeFilterTree Size filter tree, or partial size filter tree with any
 *                       empty sizes removed by pruning
 * @param category optional selected category ID
 * @param subcategories optional selected subcategory ID or IDs
 * @returns
 */
export function selectBranchFromCategory(
  sizeFilterTree: SizeFilters,
  category: SearchFilters['category'],
  subcategories: SearchFilters['subcategories']
): SizeFilters {
  if (!category) {
    return sizeFilterTree;
  }

  /** by default we should search the size tree for the selected category */
  let categoryIDs = [category];

  /**
   * in the special case where an 'all' category is selected, either:
   *  - use selected subcategories to filter sizes instead
   *  - or return the entire men's/women's side of the tree
   */
  if (TOP_LEVEL_CATEGORY_IDS.includes(category)) {
    if (subcategories?.length) {
      categoryIDs = subcategories;
    } else {
      const topLevelCategorySizes = sizeFilterTree.find(
        (node) => node.id === Number(category)
      );

      if (!topLevelCategorySizes) {
        return [];
      }

      return topLevelCategorySizes.children.filter(isBranchNode);
    }
  }

  /**
   * search one level deep in the size tree for selected category / categories
   */
  const remainingSizes = sizeFilterTree.reduce<SizeFilters>(
    (selectedBranches, root) => {
      selectedBranches.push(
        ...root.children
          .filter((child) => categoryIDs.includes(String(child.id)))
          .filter(isBranchNode)
      );

      return selectedBranches;
    },
    []
  );

  /**
   * if only one node remains, we can expand out its children
   * to save the user an extra click
   */
  return remainingSizes.length === 1
    ? remainingSizes[0].children.filter(isBranchNode)
    : remainingSizes;
}
